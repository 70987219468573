import { Card, Dialog, Divider, Modal, Paper, Snackbar } from "@mui/material";
import { PostBody } from "./post_body";
import { CommentsBar } from "./comments/comments-bar/comments-bar.component";

import { AuthorBar } from "./author-bar/author-bar.component";
import { PostBottomBar } from "./bottom-bar/bottom-bar.component";
import { PostData, PostStateMixin, PostProps } from "./post.models";

import { PostController } from "./post.controller";
import { View } from "../utils/view";

import "./post.css";
import { CommentViewer } from "./comments/comment-viewer/comment-viewer.component";

export class Post extends View<
  PostProps,
  PostData & PostStateMixin,
  PostController<PostData>
> {
  constructor(props: PostProps) {
    super(props, props.controller);
  }

  render() {
    let postContainerClassName = "post-container";

    if (this.props.showValidationErrors && this.props.hasErrors) {
      postContainerClassName += " has-errors";
    }

    return (
      <Card
        id={this.state.id}
        className={postContainerClassName}
        variant="outlined"
      >
        <AuthorBar {...this.state} />
        <PostBody
          data={this.state}
          onQuizCompleted={this.controller.onQuizCompleted}
        />
        <Divider />
        <PostBottomBar
          {...this.state}
          onDislikeClicked={this.controller.onDislikeClicked}
          onLikeClicked={this.controller.onLikeClicked}
          onShareClicked={this.controller.onShareClicked}
        />
        <Divider />
        <CommentsBar
          post={this.state}
          likeCount={this.state.likes ?? 0}
          shareCount={this.state.shares ?? 0}
          comments={this.state.comments}
          onRepliesClicked={this.controller.onRepliesClicked}
        />
        <CommentViewer {...this.state} />
        <Snackbar
          open={this.state.displayShareSnackbar}
          autoHideDuration={6000}
          onClose={this.controller.onShareDismissed}
          message="Post shared successfully!"
        />
      </Card>
    );
  }
}
