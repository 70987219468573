import { Button } from "@mui/material";
import "./comments-bar.component.css";

import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { PostComment, PostData } from "../../post.models";

export interface CommentsBarProps {
  post: PostData;
  likeCount: number;
  shareCount: number;
  comments: PostComment[] | undefined;
  onRepliesClicked: (post: PostData) => void;
}

export function CommentsBar(props: CommentsBarProps) {
  let repliesMessage = "";
  let numberComments = 0;

  if (props.comments != null) {
    repliesMessage = `${props.comments.length} comment${
      props.comments.length > 1 ? "s" : ""
    }`;
    numberComments = props.comments.length;
  }

  return (
    <div
      className={"post-replies-container"}
      id={`${props.post.id}-replies-container`}
    >
      <Button
        startIcon={<ThumbUpIcon />}
        id={`${props.post.id}-comment-like-count`}
      >
        {props.likeCount}
      </Button>
      <Button
        className="post-replies-counter"
        onClick={() => props.onRepliesClicked(props.post)}
        id={`${props.post.id}-comment-count`}
      >
        {repliesMessage} {props.shareCount} shares
      </Button>
    </div>
  );
}
