import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import "./comment-viewer.component.styles.ts";
import { PostData } from "../../post.models";
import { CommentList, CommentText } from "./comment-viewer.component.styles";
import React from "react";

export interface CommentViewerProps extends PostData {}

export function CommentViewer(props: CommentViewerProps) {
  return (
    <List sx={CommentList} id={`${props.id}-comment-viewer`}>
      {props.comments?.map((comment) => {
        return (
          <ListItem
            key={`${comment.id}-${Math.random().toString(16).slice(8)}`}
            id={`${props.id}-comment-${comment.id}`}
          >
            <ListItemAvatar id={`${props.id}-comment-${comment.id}-avatar`}>
              <Avatar
                src={comment.avatar}
                alt={comment.author}
                id={`${props.id}-comment-${comment.id}-avatar`}
              />
            </ListItemAvatar>
            <ListItemText
              primary={comment.body}
              id={`${props.id}-comment-${comment.id}-text`}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ color: "text.primary", display: "inline" }}
                    visibility={comment.author != null ? "visible" : "hidden"}
                    id={`${props.id}-comment-${comment.id}-author`}
                  >
                    {comment.author}
                  </Typography>
                  {" -- "}
                  {comment.date}
                </React.Fragment>
              }
              sx={CommentText}
            />
          </ListItem>
        );
      })}
    </List>
  );
}
