import { FormErrorController } from "../../../components/form-error-dialog/form-error-dialog.controller";
import {
  BuzzfeedQuestion,
  BuzzfeedResponse,
} from "../../../manifest/models/quiz.models";
import { ReactiveController } from "../../../utils/controller";
import { BuzzfeedQuizPost } from "../../post.models";

export interface BuzzfeedQuizState {
  viewingQuiz: boolean;
  lastPage: boolean;
  firstPage: boolean;
  question: BuzzfeedQuestion;
  responses: BuzzfeedResponse[];
  currentSelectedResponse?: BuzzfeedResponse;
}

export type ResponseSelectedCallback = (response: BuzzfeedResponse) => void;
export type QuizCompletionCallback = (
  responses: (BuzzfeedResponse | undefined)[]
) => void;

export class BuzzfeedQuizController extends ReactiveController<BuzzfeedQuizState> {
  private selectedResponses: (BuzzfeedResponse | undefined)[] = [];
  private onQuizCompleted: QuizCompletionCallback;
  private post: BuzzfeedQuizPost;
  private currentPage: number = 0;
  private bypassNullSelection: boolean = false;

  readonly pageErrorController: FormErrorController;

  constructor(post: BuzzfeedQuizPost, onQuizCompleted: QuizCompletionCallback) {
    if (post.quiz.questions == null || post.quiz.questions.length <= 0) {
      throw new Error(
        `Buzzfeed quiz data ${post.id} did not contain any questions`
      );
    }

    super({
      viewingQuiz: false,
      lastPage: post.quiz.questions.length == 1,
      firstPage: true,
      question: post.quiz.questions[0],
      responses: post.quiz.questions[0].responses,
    });

    this.post = post;
    this.onQuizCompleted = onQuizCompleted;
    this.pageErrorController = new FormErrorController({
      errorMessage:
        "Please select a response to this question before continuing. If you do not wish to answer, please press next page again.",
      buttonText: "Okay",
      title: "No response selected",
      onErrorBypassed: this.onErrorBypassed,
      isBypassable: true,
    });
  }

  onQuizClicked() {
    this.setState({
      viewingQuiz: true,
    });
  }

  onResponseSelected(response: BuzzfeedResponse) {
    this.setState({
      currentSelectedResponse: response,
    });
  }

  onErrorBypassed() {
    this.bypassNullSelection = true;
    this.onNextPageClicked();
  }

  onNextPageClicked() {
    if (
      this.state.currentSelectedResponse == null &&
      !this.bypassNullSelection
    ) {
      return this.pageErrorController.onFormValidationRejected();
    }

    this.selectedResponses[this.currentPage] =
      this.state.currentSelectedResponse;

    if (this.currentPage >= this.post.quiz.questions.length - 1) {
      this.setState({
        viewingQuiz: false,
      });

      return this.onQuizCompleted(this.selectedResponses);
    }

    let selectedResponse: BuzzfeedResponse | undefined = undefined;

    if (this.selectedResponses.length >= this.currentPage + 1) {
      selectedResponse = this.selectedResponses[this.currentPage + 1];
    }

    this.currentPage += 1;
    const isLastPage = this.currentPage == this.post.quiz.questions.length - 1;
    const question = this.post.quiz.questions[this.currentPage];
    const responses = question.responses;

    this.setState({
      currentSelectedResponse: selectedResponse,
      firstPage: this.currentPage == 0,
      question: question,
      responses: responses,
      lastPage: isLastPage,
    });
  }

  onPreviousPageClicked() {
    if (this.currentPage == 0) return;

    this.selectedResponses[this.currentPage] =
      this.state.currentSelectedResponse;

    this.currentPage -= 1;
    const question = this.post.quiz.questions[this.currentPage];
    const responses = question.responses;
    const selectedResponse = this.selectedResponses[this.currentPage];
    const isLastPage = this.currentPage == this.post.quiz.questions.length - 1;

    this.setState({
      question: question,
      responses: responses,
      firstPage: this.currentPage == 0,
      currentSelectedResponse: selectedResponse,
      lastPage: isLastPage,
    });
  }

  onQuizCancelled() {
    this.setState({
      viewingQuiz: false,
    });
  }
}
