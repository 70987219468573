import { ReactNode } from "react";
import { View } from "../../utils/view";
import "./form-error-dialog.component.css";
import {
  FormErrorController,
  FormErrorState,
} from "./form-error-dialog.controller";
import { Button, Dialog, Divider, Paper, Typography } from "@mui/material";

export interface FormErrorProps {
  controller: FormErrorController;
}

export class FormErrorDialog extends View<
  FormErrorProps,
  FormErrorState,
  FormErrorController
> {
  constructor(props: FormErrorProps) {
    super(props, props.controller);
  }

  render(): ReactNode {
    return (
      <Dialog
        open={this.state.displaying}
        onClose={this.controller.onUserConfirmed}
      >
        <Paper sx={{ borderRadius: "12px", padding: "10px" }}>
          <Typography variant="h5" sx={{ padding: "10px" }}>
            {this.state.title}
          </Typography>
          <Divider />
          <Typography variant="body1" sx={{ padding: "10px" }}>
            {this.state.errorMessage}
          </Typography>
          <div className="form-button-row">
            <Button onClick={this.controller.onUserConfirmed}>
              {this.state.buttonText}
            </Button>
          </div>
        </Paper>
      </Dialog>
    );
  }
}
