import { Container } from "@mui/material";
import { Post } from "../posts/post";
import "./social_media_feed.css";
import { PostData, PostEvent } from "../posts/post.models";
import { PostController } from "../posts/post.controller";
import { SocialMediaDriver } from "../interaction_tracking/network";

export interface SocialMediaFeedProps {
  posts: PostData[];
  onLikePostClicked: PostEvent;
  onDislikePostClicked: PostEvent;
  onSharePostClicked: PostEvent;
  onCommentsClicked: PostEvent;
  driver: SocialMediaDriver;
  testId: string;
}

export function SocialMediaFeed(props: SocialMediaFeedProps) {
  return (
    <Container maxWidth="sm" className="social-media-feed" id="feedContainer">
      {props.posts.map((post) => {
        const controller = new PostController(post, props.driver, props.testId);
        return (
          <Post
            controller={controller}
            showValidationErrors={true}
            hasErrors={false}
            key={post.id}
          />
        );
      })}
    </Container>
  );
}
