import { Box } from "@mui/material";
import {
  BuzzfeedQuestion,
  BuzzfeedResponse,
} from "../../../../../manifest/models/quiz.models";
import { ResponseSelectedCallback } from "../../buzzfeed_quiz.controller";
import { BuzzfeedResponseComponent } from "../response/response.component";
import "./question.component.css";

export interface BuzzfeedQuestionProps {
  question: BuzzfeedQuestion;
  id: string;
  currentResponse?: BuzzfeedResponse;
  onResponseSelected: ResponseSelectedCallback;
}

/**
 * Represent a single question in a buzzfeed quiz.
 */
export function BuzzfeedQuestionComponent(props: BuzzfeedQuestionProps) {
  const id = `${props.id}-question-${props.question.name}`;
  const responses = props.question.responses;
  const responseComponents = [];

  for (let i = 0; i < responses.length; i++) {
    const response = responses[i];
    const responseId = `${id}-response-${response.value}`;
    responseComponents.push(
      <BuzzfeedResponseComponent
        response={response}
        id={responseId}
        onResponseSelected={props.onResponseSelected}
        isFirstRow={i == 0}
        isLastRow={i == responses.length - 1}
        isSelected={response === props.currentResponse}
        key={responseId}
      />
    );
  }

  return (
    <Box id={`${props.id}-buzzfeed-question`} className="question-container">
      <div className="question-body">
        <div className="question-text">{props.question.body}</div>
      </div>
      <div className="responses-wrapper">
        <div className="responses">{responseComponents}</div>
      </div>
    </Box>
  );
}
