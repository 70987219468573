import {
  Box,
  Button,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";
import { BuzzfeedQuizPost } from "../../../../post.models";
import { BuzzfeedQuizController } from "../../buzzfeed_quiz.controller";
import "./quiz-post.component.css";
import { QuizTagline } from "../../../../../manifest/models/quiz.models";

export interface QuizPostProps extends BuzzfeedQuizPost {
  controller: BuzzfeedQuizController;
}

export function QuizPost(props: QuizPostProps) {
  let body;

  if (props.quiz.tagline == null) {
    body = <Typography variant="h6">{props.title}</Typography>;
  } else {
    body = <QuizTaglineComponent {...props} tagline={props.quiz.tagline} />;
  }

  return (
    <Button
      onClick={props.controller.onQuizClicked}
      variant="outlined"
      sx={{ width: "100%" }}
      id={`${props.id}-post-button`}
    >
      {body}
    </Button>
  );
}

export function QuizTaglineComponent(
  props: QuizPostProps & {
    tagline: QuizTagline;
    controller: BuzzfeedQuizController;
  }
) {
  return (
    <Box
      sx={{
        backgroundImage: `url(${props.tagline.backgroundImage})`,
        backgroundSize: "cover",
        height: props.height,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "10px",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          backgroundColor: "white",
          borderRadius: "12px",
          margin: "0 30px",
        }}
      >
        {props.tagline.title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ImageList
          cols={3}
          sx={{
            display: props.tagline.images != null ? "grid" : "none",
          }}
        >
          {props.tagline.images?.map((image) => {
            return (
              <ImageListItem key={image}>
                <img src={image} />
              </ImageListItem>
            );
          }) ?? []}
        </ImageList>
        <Box
          sx={{
            margin: "10px 75px",
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "12px",
          }}
        >
          <Typography variant="body1">{props.tagline.subtitle}</Typography>
        </Box>
        <Box
          sx={{
            margin: "10px 125px",
            bgcolor: "error.main",
            color: "white",
            "&:Hover": {
              bgcolor: "primary.dark",
            },
          }}
        >
          {props.tagline.buttonText ?? "Take Quiz"}
        </Box>
      </Box>
    </Box>
  );
}
