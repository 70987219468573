import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import PeopleIcon from "@mui/icons-material/People";
import GroupsIcon from "@mui/icons-material/Groups";

import "./left-sidebar.component.css";

export interface Shortcut {
  name: string;
}

export interface LeftSidebarProps {
  pageCount: number;
  shortcuts: Shortcut[];
}

export function LeftSidebar(props: LeftSidebarProps) {
  return (
    <div className="left-sidebar-outer">
      <List>
        <ListItem id={"left-sidebar-pages-button"}>
          <ListItemButton id={"left-sidebar-pages-button"}>
            <ListItemIcon id={"left-sidebar-pages-button"}>
              <OutlinedFlagIcon
                fontSize="large"
                id={"left-sidebar-pages-button"}
              />
            </ListItemIcon>
            <ListItemText
              id={"left-sidebar-pages-button"}
              primary="Pages"
              secondary={`${props.pageCount} new`}
              className="sidebar-title page-count-title"
            />
          </ListItemButton>
        </ListItem>
        <ListItem id={"left-sidebar-friends-button"}>
          <ListItemButton id={"left-sidebar-friends-button"}>
            <ListItemIcon id={"left-sidebar-friends-button"}>
              <PeopleIcon
                fontSize="large"
                color="secondary"
                id={"left-sidebar-friends-button"}
              />
            </ListItemIcon>
            <ListItemText
              primary="Friends"
              className="sidebar-title"
              id={"left-sidebar-friends-button"}
            />
          </ListItemButton>
        </ListItem>
        <ListItem id={"left-sidebar-groups-button"}>
          <ListItemButton id={"left-sidebar-groups-button"}>
            <ListItemIcon id={"left-sidebar-groups-button"}>
              <GroupsIcon
                fontSize="large"
                color="success"
                id={"left-sidebar-groups-button"}
              />
            </ListItemIcon>
            <ListItemText
              primary="Groups"
              className="sidebar-title"
              id={"left-sidebar-groups-button"}
            />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <ShortcutList shortcuts={props.shortcuts} />
    </div>
  );
}

export function ShortcutList(props: { shortcuts: Shortcut[] }) {
  return (
    <List id={"left-sidebar-shortcuts-list"}>
      <ListItem disablePadding id={"left-sidebar-shortcuts-list"}>
        <ListItemText
          primary="Shortcuts"
          className="sidebar-section-header"
          id={"left-sidebar-shortcuts-list"}
        />
      </ListItem>
      {props.shortcuts.map((shortcut) => {
        return (
          <ListItem key={shortcut.name} id={`shortcut-${shortcut.name}`}>
            <ListItemButton id={`shortcut-${shortcut.name}`}>
              <ListItemText
                primary={shortcut.name}
                className="sidebar-title"
                id={`shortcut-${shortcut.name}`}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}
