import { Button, IconButton } from "@mui/material";
import "./bottom-bar.component.css";

import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ShareIcon from "@mui/icons-material/Share";
import { PostData, PostEvent, UserLikeState } from "../post.models";

export interface PostBottomBarProps extends PostData {
  onLikeClicked: PostEvent;
  onDislikeClicked: PostEvent;
  onShareClicked: PostEvent;
  userLikeState: UserLikeState;
  userShared: boolean;
}

export function PostBottomBar(props: PostBottomBarProps) {
  let likeButtonColor = "primary.main";
  let dislikeButtonColor = "secondary.main";
  let shareButtonColor = "success.main";

  switch (props.userLikeState) {
    case "user-liked":
      likeButtonColor = "primary.light";
      break;
    case "user-disliked":
      dislikeButtonColor = "secondary.light";
      break;
    case "none":
      break;
  }

  if (props.userShared) {
    shareButtonColor = "success.light";
  }

  return (
    <div
      className="bottom-bar-container"
      id={`${props.id}-bottom-bar-container`}
    >
      <Button
        variant="contained"
        startIcon={<ThumbUpIcon id={`${props.id}-like-button`} />}
        onClick={() => props.onLikeClicked(props)}
        sx={{ bgcolor: likeButtonColor }}
        id={`${props.id}-like-button`}
      >
        Like
      </Button>
      <Button
        variant="contained"
        startIcon={<ThumbDownIcon id={`${props.id}-dislike-button`} />}
        onClick={() => props.onDislikeClicked(props)}
        sx={{ bgcolor: dislikeButtonColor }}
        id={`${props.id}-dislike-button`}
      >
        Dislike
      </Button>
      <Button
        variant="contained"
        startIcon={<ShareIcon id={`${props.id}-share-button`} />}
        onClick={() => props.onShareClicked(props)}
        sx={{ bgcolor: shareButtonColor }}
        id={`${props.id}-share-button`}
      >
        Share
      </Button>
    </div>
  );
}
