import { ReactiveController } from "../../utils/controller";

export interface FormErrorState {
  displaying: boolean;
  errorMessage: string;
  title: string;
  buttonText: string;
}

export interface FormErrorOptions {
  errorMessage: string;
  title: string;
  buttonText: string;
  isBypassable: boolean;
  onErrorBypassed: () => void;
}

export class FormErrorController extends ReactiveController<FormErrorState> {
  private onErrorBypassed: () => void;
  private isBypassable: boolean;
  private userConfirmedError: boolean = false;

  constructor(options: FormErrorOptions) {
    super({
      ...options,
      displaying: false,
    });
    this.onErrorBypassed = options.onErrorBypassed;
    this.isBypassable = options.isBypassable;
  }

  onFormValidationRejected() {
    if (this.isBypassable && this.userConfirmedError) {
      return this.onErrorBypassed();
    }

    this.setState({
      displaying: true,
    });
  }

  onUserConfirmed() {
    this.userConfirmedError = true;

    this.setState({
      displaying: false,
    });
  }
}
