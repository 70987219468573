import { Container, Dialog, Typography } from "@mui/material";
import "./manifest-error-screen.component.css";
import { ExperimentLoadError } from "../../index.models";
import React from "react";

export function LoadErrorScreen(props: {
  error: ExperimentLoadError;
  contact?: string;
  exception?: Error;
}) {
  let message;

  switch (props.error) {
    case "no-manifest":
      message = (
        <React.Fragment>
          <p>
            FATAL Error when loading experiment_manifest! If you are a
            participant seeing this message, please contact your Experiment POC
            for assistance. This is likely a misconfiguration of your server.
            Ensure that your web server is hosting your experiment_manifest.json
            file at /experiment_manifest.json and reload.
          </p>
          <p>{props.exception?.stack}</p>
        </React.Fragment>
      );
      break;
    case "no-condition":
    case "no-subject-id":
    default:
      let contactMessage = <span>experiment POC</span>;

      if (props.contact != null) {
        contactMessage = (
          <span>
            experiment POC (
            <a href={`mailto:${props.contact}`}>{props.contact}</a>)
          </span>
        );
      }

      message = (
        <Typography>
          The experiment link you entered is invalid. Please verify you copied
          the entire link provided to you. If this issue persists, please
          contact your {contactMessage} to receive a new link.
        </Typography>
      );
      break;
  }

  return (
    <Dialog open={true} className="modal-root">
      <Container
        maxWidth="md"
        className="error-container-outer"
        sx={{ flexDirection: "column", height: "fit-content" }}
      >
        {message}
      </Container>
    </Dialog>
  );
}
