import { Button } from "@mui/material";
import { BuzzfeedResponse } from "../../../../../manifest/models/quiz.models";
import { ResponseSelectedCallback } from "../../buzzfeed_quiz.controller";
import "./response.component.css";

export interface BuzzfeedResponseProps {
  response: BuzzfeedResponse;
  id: string;
  onResponseSelected: ResponseSelectedCallback;
  isSelected: boolean;
  isFirstRow: boolean;
  isLastRow: boolean;
}

/**
 * Represent a response to a single buzzfeed quiz question.
 */
export function BuzzfeedResponseComponent(props: BuzzfeedResponseProps) {
  let className = "response-row";

  if (props.isFirstRow) {
    className += " first-response-row";
  }
  if (props.isLastRow) {
    className += " last-response-row";
  }
  if (props.isSelected) {
    className += " selected-response";
  }

  return (
    <Button
      id={props.id}
      className={className}
      color={props.isSelected ? "quizButtonSelected" : "quizButton"}
      variant="contained"
      onClick={() => props.onResponseSelected(props.response)}
    >
      {props.response.text}
    </Button>
  );
}
