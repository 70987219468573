import autoBind from "auto-bind";

import { randomNumber, shuffle } from "../utils/randomization";
import {
  FeedGenerationStrategy,
  FeedSequenceItem,
  GenerationConstraint,
  SequenceType,
  RandomizationStrategy,
} from "./generation.models";
import { ManifestParser } from "../manifest/parser";
import { PostData, YoutubeVideoPost } from "../posts/post.models";
import { AuthorGenerator } from "./user_data/author.generator";
import { AvatarGenerator } from "./user_data/avatar.generator";
import { PostDateGenerator } from "./user_data/date.generator";
import { CommentGenerator } from "./user_data/comment.generator";
import { InteractionGenerator } from "./user_data/interaction.generator";
import { dumpCSV } from "../utils/csv_dump";
import { Author } from "../manifest/models/author.models";
import { SponsoredPostData } from "../body/desktop-body.component/right-sidebar/right-sidebar.component";

export class FeedGenerator {
  private parser: ManifestParser;
  private strategy: FeedGenerationStrategy;
  private authorGenerator: AuthorGenerator;
  private avatarGenerator: AvatarGenerator;
  private postDateGenerator: PostDateGenerator;
  private commentGenerator: CommentGenerator;
  private likeGenerator: InteractionGenerator;
  private shareGenerator: InteractionGenerator;

  chosenSponsoredAd!: SponsoredPostData;
  userBirthdays: Author[] = [];
  contacts: (Author & { avatar: string })[] = [];

  private articles: PostData[] = [];
  private advertisements: SponsoredPostData[] = [];
  private infographics: PostData[] = [];
  private memes: PostData[] = [];
  private quizzes: PostData[] = [];
  private posts: PostData[] = [];
  private videos: YoutubeVideoPost[] = [];
  private filler: PostData[] = [];

  private currentArticle = 0;
  private currentAdvertisement = 0;
  private currentInfographic = 0;
  private currentMeme = 0;
  private currentQuiz = 0;
  private currentVideo = 0;
  private currentFiller = 0;

  constructor(parser: ManifestParser) {
    autoBind(this);
    this.parser = parser;
    this.strategy = this.parser.strategy;
    this.authorGenerator = new AuthorGenerator(this.parser.authorBank);
    this.avatarGenerator = new AvatarGenerator(this.parser.avatarBank);
    this.commentGenerator = new CommentGenerator(
      this.parser.commentBank,
      this.parser.commentStrategy,
      this.avatarGenerator,
      this.authorGenerator
    );
    this.likeGenerator = new InteractionGenerator(
      this.parser.likeStrategy,
      "likes"
    );
    this.shareGenerator = new InteractionGenerator(
      this.parser.shareStrategy,
      "shares"
    );
    this.postDateGenerator = new PostDateGenerator(this.parser.dateStrategy);
  }

  generate(): PostData[] {
    this.memes = this.parser.memes.slice();
    this.articles = this.parser.articles.slice();
    this.advertisements = this.parser.advertisements.slice();
    this.infographics = this.parser.infographics.slice();
    this.quizzes = this.parser.quizzes.slice();
    this.videos = this.parser.videos.slice();
    this.filler = this.parser.fillerPosts.slice();

    this.randomizeSets();

    this.generateSidebarContent();

    if (
      this.strategy.appearance_order == null ||
      this.strategy.appearance_order.length == 0
    ) {
      /// If we weren't given an appearance order, just show them in line.
      return [
        ...this.articles,
        ...this.advertisements,
        ...this.infographics,
        ...this.memes,
      ];
    }

    let done = false;
    let index = 0;

    while (!done) {
      const item =
        this.strategy.appearance_order[
          index % this.strategy.appearance_order.length
        ];

      done = this.processSequenceItem(item);
      index++;
    }

    this.posts = this.postDateGenerator.addPostDates(this.posts);
    this.posts = this.authorGenerator.addPostAuthors(this.posts);
    this.posts = this.avatarGenerator.addPostAvatars(this.posts);
    this.posts = this.commentGenerator.addPostComments(this.posts);
    this.posts = this.likeGenerator.addPostInteractions(this.posts);
    this.posts = this.shareGenerator.addPostInteractions(this.posts);

    console.debug(
      "feed_generation_stats",
      dumpCSV([
        [
          "post-id",
          "like-count",
          "share-count",
          "comment-count",
          "author",
          "post-date",
        ],
        ...this.posts.map((post) => {
          return [
            post.id,
            post.likes,
            post.shares,
            post.comments?.length,
            post.author,
            post.date,
          ];
        }),
      ])
    );

    return this.posts;
  }

  processSequenceItem(item: FeedSequenceItem): boolean {
    if (this.validateConstraints(this.allConstraints())) {
      return true;
    }

    const type = item.type;

    const min = item.amount.min;
    const max = item.amount.max;
    const numberToAdd = Math.min(Math.trunc(Math.random() * (max - min) + min));

    for (let i = 0; i < numberToAdd; i++) {
      /// If other constraints aren't met, but this item's are, skip the item but iterate again.
      if (this.validateConstraint(this.getConstraintFor(type))) {
        return false;
      }

      const post = this.getNextPostFor(type);
      this.posts.push(post);
    }

    if (this.validateConstraints(this.allConstraints())) {
      return true;
    }

    return false;
  }

  allConstraints(): GenerationConstraint[] {
    return [
      this.getConstraintFor("advertisements"),
      this.getConstraintFor("memes"),
      this.getConstraintFor("infographics"),
      this.getConstraintFor("articles"),
      this.getConstraintFor("quizzes"),
    ].filter(
      (constraint): constraint is GenerationConstraint => constraint != null
    );
  }

  getConstraintFor(type: SequenceType): GenerationConstraint | undefined {
    let constraint;
    switch (type) {
      case "memes":
        constraint = this.strategy.constraints?.memes;
        break;
      case "advertisements":
        constraint = this.strategy.constraints?.advertisements;
        break;
      case "articles":
        constraint = this.strategy.constraints?.articles;
        break;
      case "infographics":
        constraint = this.strategy.constraints?.infographics;
        break;
      case "quizzes":
        constraint = this.strategy.constraints?.quiz;
        break;
      case "videos":
        constraint = this.strategy.constraints?.videos;
        break;
      case "filler":
        constraint = this.strategy.constraints?.filler;
        break;
      default:
        throw new Error(`Unknown appearance order type ${type}`);
    }

    return {
      ...constraint,
      type: type,
    };
  }

  getNextPostFor(type: SequenceType): PostData {
    switch (type) {
      case "memes":
        return this.memes[this.currentMeme++ % this.memes.length];
      case "advertisements":
        return this.advertisements[
          this.currentAdvertisement++ % this.advertisements.length
        ];
      case "articles":
        return this.articles[this.currentArticle++ % this.articles.length];
      case "infographics":
        return this.infographics[
          this.currentInfographic++ % this.infographics.length
        ];
      case "quizzes":
        return this.quizzes[this.currentQuiz++ % this.quizzes.length];
      case "videos":
        return this.videos[this.currentVideo++ % this.videos.length];
      case "filler":
        return this.filler[this.currentFiller++ % this.filler.length];
      default:
        throw new Error(`Unknown appearance order type ${type}`);
    }
  }

  validateConstraints(constraints: GenerationConstraint[]): boolean {
    return (
      constraints.filter(this.validateConstraint).length === constraints.length
    );
  }

  validateConstraint(constraint: GenerationConstraint | undefined): boolean {
    if (constraint == null) return false;
    if (constraint.type == null) {
      throw new Error(
        "Unable to validate a constraint that doesn't have sequence type attached!"
      );
    }

    let items;
    let currentIndex;

    switch (constraint.type) {
      case "memes":
        items = this.memes;
        currentIndex = this.currentMeme;
        break;
      case "advertisements":
        items = this.advertisements;
        currentIndex = this.currentAdvertisement;
        break;
      case "articles":
        items = this.articles;
        currentIndex = this.currentArticle;
        break;
      case "infographics":
        items = this.infographics;
        currentIndex = this.currentInfographic;
        break;
      case "quizzes":
        items = this.quizzes;
        currentIndex = this.currentQuiz;
        break;
      case "videos":
        items = this.videos;
        currentIndex = this.currentVideo;
        break;
      case "filler":
        items = this.filler;
        currentIndex = this.currentFiller;
    }

    /// If the constraint specifies to ensure we use all the items, and we haven't yet, DON'T skip.
    if (constraint.use_all && currentIndex < items.length) return false;

    /// If the constraint specifies to NOT allow repeats, and we've used all our items, SKIP.
    if (!constraint.allow_repeats && currentIndex >= items.length) return true;

    /// If no other constraint criteria was flagged, return false, meaning INCLUDE this next item.
    return false;
  }

  randomizeSets() {
    this.memes = this.randomizeSet(
      this.memes,
      this.strategy.randomization?.memes ?? "fixed_order"
    );
    this.articles = this.randomizeSet(
      this.articles,
      this.strategy.randomization?.articles ?? "fixed_order"
    );
    this.infographics = this.randomizeSet(
      this.infographics,
      this.strategy.randomization?.infographics ?? "fixed_order"
    );
    this.advertisements = this.randomizeSet(
      this.advertisements,
      this.strategy.randomization?.advertisements ?? "fixed_order"
    );
    this.quizzes = this.randomizeSet(
      this.quizzes,
      this.strategy.randomization?.quizzes ?? "fixed_order"
    );
    this.videos = this.randomizeSet(
      this.videos,
      this.strategy.randomization?.videos ?? "fixed_order"
    );
    this.filler = this.randomizeSet(
      this.filler,
      this.strategy.randomization?.filler ?? "fixed_order"
    );
  }

  randomizeSet<T extends PostData>(
    set: T[],
    strategy: RandomizationStrategy
  ): T[] {
    if (strategy == "fixed_order") return set.slice();

    return shuffle(set);
  }

  generateSidebarContent() {
    this.chooseSponsoredAd();
    this.chooseBirthdays();
    this.chooseContactList();
  }

  chooseSponsoredAd() {
    /// Since we've shuffled the advertisements, we can just take the first index for a "random" ad.
    this.chosenSponsoredAd = this.advertisements[0];
    /// Remove the chosen ad from the list of ads.
    /// TODO: Do we want to remove it or dupe it?
    this.advertisements = this.advertisements.slice(1);
  }

  chooseBirthdays() {
    this.userBirthdays = [
      this.authorGenerator.getNextObject(),
      this.authorGenerator.getNextObject(),
    ];
  }

  chooseContactList() {
    const numberOfContacts = randomNumber(5, 10);
    this.contacts = [];

    for (let i = 0; i < numberOfContacts; i++) {
      this.contacts.push({
        ...this.authorGenerator.getNextObject(),
        avatar: this.avatarGenerator.getNextObject().filename,
      });
    }
  }

  get name() {
    return this.parser.name;
  }

  get instructions() {
    return this.parser.instructions;
  }

  get contact() {
    return this.parser.contact;
  }
}
