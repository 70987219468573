import { Box } from "@mui/material";
import { YoutubeVideoPost } from "../post.models";

export interface YoutubeVideoProps extends YoutubeVideoPost {}

export function YoutubeVideo(props: YoutubeVideoProps) {
  return (
    <Box className="youtube-player-container" sx={{ width: "100%" }}>
      <iframe
        width={"100%"}
        height={props.height}
        src={props.url}
        title={props.title}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </Box>
  );
}
