import "./quiz-navigation-row.component.css";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import { Box, Container, IconButton } from "@mui/material";

export interface QuizNavigationRowProps {
  onPreviousPageClicked: () => void;
  onNextPageClicked: () => void;
  firstPage: boolean;
  lastPage: boolean;
}

export function QuizNavigationRow(props: QuizNavigationRowProps) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: props.firstPage ? "end" : "space-between",
        margin: "0",
        backgroundColor: "lightblue",
      }}
    >
      <IconButton
        onClick={props.onPreviousPageClicked}
        size="large"
        sx={{
          display: props.firstPage ? "none" : "initial",
          color: "white",
        }}
      >
        <NavigateBeforeIcon />
      </IconButton>
      <IconButton onClick={props.onNextPageClicked} size="large">
        <NavigateNextIcon
          sx={{ display: props.lastPage ? "none" : "initial", color: "white" }}
        />
        <FactCheckIcon sx={{ display: props.lastPage ? "initial" : "none" }} />
      </IconButton>
    </Box>
  );
}
