import { BuzzfeedQuizPost } from "../../post.models";
import "./buzzfeed_quiz.css";
import {
  BuzzfeedQuizController,
  BuzzfeedQuizState,
  QuizCompletionCallback,
} from "./buzzfeed_quiz.controller";
import { View } from "../../../utils/view";
import React from "react";
import { QuizDialogComponent } from "./components/quiz-dialog/quiz-dialog.component";
import { QuizPost } from "./components/quiz-post/quiz-post.component";

export interface BuzzfeedQuizProps extends BuzzfeedQuizPost {
  onQuizCompleted: QuizCompletionCallback;
}

/**
 * Represent an entire buzzfeed quiz in the social media emulator.
 */
export class BuzzfeedQuizComponent extends View<
  BuzzfeedQuizProps,
  BuzzfeedQuizState,
  BuzzfeedQuizController
> {
  constructor(props: BuzzfeedQuizProps) {
    super(props, new BuzzfeedQuizController(props, props.onQuizCompleted));
  }

  render() {
    return (
      <React.Fragment>
        <QuizPost {...this.props} controller={this.controller} />
        <QuizDialogComponent {...this.props} controller={this.controller} />
      </React.Fragment>
    );
  }
}
