import { ReactiveController } from "./controller";
import autoBind from "auto-bind";
import { SubscribingComponent } from "./subscriber";

/**
 * A view is a react component that ties in with an application controller to manage business logic and events.
 * It manages initializing and disposing the controller based on component lifecycle events.
 * It also hooks up the controller's state observable to the component's state via setState.
 */
export class View<
  Props,
  State,
  Controller extends ReactiveController<State>
> extends SubscribingComponent<Props, State> {
  protected controller: Controller;

  constructor(props: Props, controller: Controller) {
    super(props);
    autoBind(this);
    this.controller = controller;
    this.state = this.controller.state;
    this.controller.initialize();
  }

  componentDidMount(): void {
    this.subscribe(this.controller.stream.subscribe(this.onStateUpdated));
  }

  onStateUpdated(state: State) {
    this.setState(state);
  }

  componentWillUnmount(): void {
    super.componentWillUnmount();
    this.controller.dispose();
  }
}
