import { createTheme } from "@mui/material";

// Augment the palette to include an ochre color
declare module "@mui/material/styles" {
  interface Palette {
    quizButton: Palette["primary"];
    quizButtonSelected: Palette["primary"];
  }

  interface PaletteOptions {
    quizButton?: PaletteOptions["primary"];
    quizButtonSelected?: PaletteOptions["primary"];
  }
}

// Update the Button's color options to include an ochre option
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    quizButton: true;
    quizButtonSelected: true;
  }
}

export const theme = createTheme({
  palette: {
    mode: "light",

    quizButton: {
      main: "#FF413C",
      light: "#FF413C",
      dark: "#FF3A33",
      contrastText: "#FFFFFF",
    },
    quizButtonSelected: {
      main: "#53D8FB",
      light: "#53D8FB",
      dark: "#38D0FA",
      contrastText: "#FFFFFF",
    },
  },
});
