import { Dialog, Typography, Divider, Box } from "@mui/material";
import React from "react";
import { FormErrorDialog } from "../../../../../components/form-error-dialog/form-error-dialog.component";
import { View } from "../../../../../utils/view";
import {
  BuzzfeedQuizState,
  BuzzfeedQuizController,
  QuizCompletionCallback,
} from "../../buzzfeed_quiz.controller";
import { BuzzfeedQuestionComponent } from "../question/question.component";
import { QuizNavigationRow } from "../quiz-navigation-row/quiz-navigation-row.component";
import { BuzzfeedQuizPost } from "../../../../post.models";

import "./quiz-dialog.component.css";

export interface QuizDialogProps extends BuzzfeedQuizPost {
  controller: BuzzfeedQuizController;
  onQuizCompleted: QuizCompletionCallback;
}

/**
 * Represent an entire buzzfeed quiz in the social media emulator.
 */
export class QuizDialogComponent extends View<
  QuizDialogProps,
  BuzzfeedQuizState,
  BuzzfeedQuizController
> {
  constructor(props: QuizDialogProps) {
    super(props, props.controller);
  }

  render() {
    let backgroundImage;
    let backgroundSize;

    if (this.state.question?.backgroundImage != null) {
      backgroundImage = `url(${this.state.question?.backgroundImage})`;
      backgroundSize = "cover";
    }

    return (
      <React.Fragment>
        <Dialog
          open={this.state.viewingQuiz}
          onClose={this.controller.onQuizCancelled}
          id={`${this.props.id}-buzzfeed-quiz`}
        >
          <Box
            className="quiz-dialog-wrapper"
            sx={{
              backgroundImage: backgroundImage,
              backgroundSize: backgroundSize,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                padding: "10px",
                backgroundColor: "lightblue",
                color: "white",
              }}
            >
              {this.props.title}
            </Typography>
            <Divider />
            <BuzzfeedQuestionComponent
              question={this.state.question}
              currentResponse={this.state.currentSelectedResponse}
              onResponseSelected={this.controller.onResponseSelected}
              id={this.props.id}
            />
            <QuizNavigationRow
              onPreviousPageClicked={this.controller.onPreviousPageClicked}
              onNextPageClicked={this.controller.onNextPageClicked}
              firstPage={this.state.firstPage}
              lastPage={this.state.lastPage}
            />
          </Box>
        </Dialog>
        <FormErrorDialog controller={this.controller.pageErrorController} />
      </React.Fragment>
    );
  }
}
